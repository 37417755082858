<template>
    <div class="list-box" id="list-box">
        <title-nav :title="$route.query.q === 'fans' ? '粉丝列表' : '关注列表'"/>
        <div class="content" v-if="lists.length > 0">
            <div class="row" v-for="(item, index) in lists" :key="index">
                <div class="userInfo">
                    <img :src="item.avatarUrl" alt="" width="40" height="40" @click="goAuthorPage(item.uid)">
                    <div class="text">
                        <div class="nickname">{{ item.nickname }}</div>
                    </div>
                </div>
                <div v-if="item.isLoginUser === true"></div>
                <div class="followed-button grey" v-else-if="item.isFollowed && item.focusEachOther" @click="handleFollow(item.isFollowed, index)">互相关注</div>
                <div class="followed-button grey" v-else-if="item.isFollowed && !item.focusEachOther" @click="handleFollow(item.isFollowed, index)">已关注</div>
                <div class="follow-button primary" v-else @click="handleFollow(item.isFollowed, index)"><span>+</span>关注</div>
            </div>
        </div>
        <div class="none-list" v-else-if="!$store.state.loading && lists.length === 0">
            <img src="../../assets/images/none.svg" alt="">
            <div class="none">没有更多内容</div>
        </div>
    </div>
</template>

<script>
import TitleNav from '../../components/nav/TitleNav.vue'
import { unfollowAuthor, followAuthor, followList, fansList } from '@/api/api'

export default {
    name: 'FollowList',
    components: {
        TitleNav
    },
    created(){
        this.getData()
    },
    mounted(){
        if(this.$store.state.userAgent !== 'Mobile'){
            document.getElementById("list-box").style.cssText= 'margin-top: 58px;border: 1px solid rgba(0,0,0,0.05);min-height:calc(100vh - 58px);';
        }
    },
    props:{

    },
    data:()=>{
        return {
            lists:[],
            isLogin: localStorage.getItem('token') ? true : false,
        }
    },
    methods:{
        getData(){
            this.$store.commit('Set_Loading_State', true)
            if(this.$route.query.q === 'fans'){
                let uid = this.isLogin ? String(JSON.parse(localStorage.getItem('userInfo'))[0].uid) : ''
                // 请求fansList
                fansList({ authorUid: this.$route.query.uid, limit: 100, uid: uid }).then(res=>{
                    let tempArr = []
                    res.data.data.fansList.forEach(item => {
                        let temp = {}
                        temp.intro = item.intro
                        temp.nickname = item.nickname
                        temp.uid = item.uid
                        if(Number(uid) === Number(item.uid)){
                            temp.isLoginUser = true
                        } else {
                            temp.isLoginUser = false
                        }
                        temp.isFollowed = item.isFollowed
                        temp.focusEachOther = item.focusEachOther
                        if(!item.avatarUrl){
                            temp.avatarUrl = require('../../assets/images/avatar.png')
                        } else {
                            // temp.avatarUrl = item.avatarUrl
                            temp.avatarUrl = item.miniAvatarUrl
                        }
                        tempArr.push(temp)
                    })
                    this.lists = tempArr
                    this.$store.commit('Set_Loading_State', false)
                })
            } else {
                let uid = this.isLogin ? String(JSON.parse(localStorage.getItem('userInfo'))[0].uid) : ''
                // 请求followList
                followList({ authorUid: this.$route.query.uid, limit: 100, uid: uid}).then(res=>{
                    let tempArr = []
                    res.data.data.followList.forEach(item => {
                        let temp = {}
                        temp.intro = item.intro
                        temp.nickname = item.nickname
                        temp.uid = item.uid
                        if(Number(uid) === Number(item.uid)){
                            temp.isLoginUser = true
                        } else {
                            temp.isLoginUser = false
                        }
                        temp.isFollowed = item.isFollowed
                        temp.focusEachOther = item.focusEachOther
                        if(!item.avatarUrl){
                            temp.avatarUrl = require('../../assets/images/avatar.png')
                        } else {
                            // temp.avatarUrl = item.avatarUrl
                            temp.avatarUrl = item.miniAvatarUrl
                        }
                        tempArr.push(temp)
                    })
                    this.lists = tempArr
                    this.$store.commit('Set_Loading_State', false)
                })
            }
        },
        handleFollow(isFollowed, index){
            if(isFollowed){
                // 请求unFollow接口
                let isLogin = true
                if(isLogin){
                    unfollowAuthor({ uid: JSON.parse(localStorage.getItem('userInfo'))[0].uid, unfollowUid: this.lists[index].uid  }).then(()=>{
                        console.log('取消关注成功')
                        this.lists[index].isFollowed = !this.lists[index].isFollowed
                    })
                } else {
                    // 弹窗跳转到登录页
                    // this.isFollowed = !this.isFollowed
                }
            } else {
                // 请求follow接口
                let isLogin = true
                if(isLogin){
                    followAuthor({ uid: JSON.parse(localStorage.getItem('userInfo'))[0].uid, followUid: this.lists[index].uid }).then(()=>{
                        console.log('关注成功')
                        this.lists[index].isFollowed = !this.lists[index].isFollowed
                    })
                } else {
                // 弹窗跳转到登录页
                // this.isFollowed = !this.isFollowed
                }
            }
        },
        goAuthorPage(uid){
            this.$router.push({ path:'/author', query:{ uid: uid } })
        }
    },
}
</script>

<style scoped lang="scss">
    .list-box {
        background: #fff;
        min-height: 100vh;
        box-sizing: border-box;
        max-width: 625px;
        margin: 0 auto;
        .content {
            padding: 50px 0 10px 0;
            .row {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 15px 15px;
                .userInfo {
                    display: flex;
                    align-items: center;
                    img {
                        margin-right: 5px;
                        border-radius: 50%;
                    }
                    .text {
                        .nickname {
                            font-size: 16px;
                        }
                    }
                }
                .followed-button {
                    min-width: 70px;
                    padding: 0px 1px;
                    height: 30px;
                    display: flex;
                    line-height: 30px;
                    border-radius: 15px;
                    font-size: 15px;
                    align-items: center;
                    color: #fff;
                    justify-content: center;
                    border: 1px solid #ccc;
                    box-shadow: 1px 1px 4px #888;
                }
                    .follow-button {
                        min-width: 70px;
                        padding: 0px 1px;
                        height: 30px;
                        display: flex;
                        line-height: 30px;
                        border-radius: 15px;
                        align-items: center;
                        font-size: 15px;
                        color: #fff;
                        justify-content: center;
                        border: 1px solid #ea6f7b;
                        box-shadow: 1px 1px 4px #ea6f7b;
                        span {
                            font-size: 20px;
                            color: #fff;
                            padding-right: 2px;
                        }
                    }
            }
        }
        .none-list {
            margin: 180px auto;
            width: 100%;
            max-width: 625px;
            position: fixed;
            .none {
                margin: 10px 0 0;
                color: #969699;
            }
        }
    }
</style>